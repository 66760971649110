import axios from 'axios'
import { parse } from 'date-fns'

// General functions

export const lazyRetry = (componentImport) => {
    return new Promise((resolve, reject) => {
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
        )
        componentImport().then((component) => {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'false') 
            resolve(component)
        }).catch((error) => {
            if (!hasRefreshed) { 
                window.sessionStorage.setItem('retry-lazy-refreshed', 'true') 
                return window.location.reload() 
            }
            reject(error) 
        })
    })
}

export const formatPrice = (x, s) => {    
    if (typeof x === 'undefined' || (!x && x !== 0)) {
        return 
    }
    let y = Number(x).toFixed()
    y = y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
    
    y += ` ${s  
        ? s !== 'none'
            ? s
            : '' 
        : process.env.REACT_APP_CURRENCY_SYMBOL}`
    return y
} 

export const cloudinaryURL = (url) => {    
    if (!url || !url.includes('/upload/')) return url
    return url.replace('/upload/', '/upload/q_auto,f_auto/')
}

export const scrollAnimation = () => {
    const appearOptions = {
        threshold: 0,
        routeMargin: '0px 0px -250px 0px'
    }   
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('appear')
            } else {
                entry.target.classList.remove('appear')
            }
        })
    }, appearOptions)

    const faders = document.querySelectorAll('.fade-in')
    faders.forEach((el) => observer.observe(el))
} 

// Form functions

export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
} 

export const isPasswordValid = (password) => {
    return password.length >= 6
}

export const checkUrl = (value) => {        
    let urlRegex = /^https?:\/\//    
    return urlRegex.test(value) ? true : false
}

export const sanitizeInput = (value) => {
    value = value.replace(/[^\w& -]/ig, '')
    value = value.replace(/ /ig, '-')
    return value.toLowerCase()  
} 

// Db functions

const calculatePositions = (path, value) => {    
    
    return new Promise(
        async (resolve) => { // pages
            const data = await axios.get( `/api/v1/${path}?category=${value}` )
            resolve( { data } ) 
        }
    )        
}

export const handlePositions = (path, value, setPositions, mode) => {

    calculatePositions(path, value)
        .then(
            function(result) {

                const data = result.data.data.merchs
                                          
                const array = []  

                const length = mode === 'create' ? data.length + 1 : data.length      
                
                for (let i = 1; i <= length; i++) {
                    array.push(
                        {
                            label: i,
                            value: i
                        }
                    )
                }
                return setPositions(array)    
            },
            function(error) {console.log(error)}
        )
}

export const setMainImage = (e, setFeaturedImagePreview, setFeaturedImage) => {
    const reader = new FileReader()
    reader.onload = () => {
        if(reader.readyState === 2) {
            setFeaturedImagePreview(reader.result)
            setFeaturedImage(reader.result)
        }
    }
    return reader.readAsDataURL(e.target.files[0])        
} 

export const setImageArray = (e, setImagesPreview, setImages) => {        
    const files = Array.from(e.target.files)
    setImagesPreview([])
    setImages([])
    files.forEach(file => {
        const reader = new FileReader()
        reader.onload = () => {
            if(reader.readyState === 2) {
                setImagesPreview(oldArray => [...oldArray, reader.result])
                setImages(oldArray => [...oldArray, reader.result])
            }
        }
        return reader.readAsDataURL(file)
    })   
} 

export const urlencodeFormData = (formData) => {
    const params = new URLSearchParams()
    for( let pair of formData.entries() ) {
        typeof pair[1]=='string' && params.append( pair[0], pair[1] )
    }
    return params.toString()
}

// Date functions

const correctTimeZone = (dateStr) => {   
    const d = new Date(dateStr)
    return new Date(d.toLocaleString('en-US', { timeZone: 'Asia/Bangkok' }))  
}

export const discountValid = (seasonStart, seasonTwo) => {
    const isAfterAugust = new Date().getMonth() > 7
    return seasonStart === seasonTwo || !isAfterAugust
} 

export const formatDate = (dateStr) => {
    const d     = new Date(dateStr)
    const day   = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
    const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
    const year  = d.getFullYear() 
    return new Date(`${year}-${month}-${day}`).getTime()
}

export const formatMyDate = (dateStr) => {  
    const d = new Date(correctTimeZone(dateStr))    
    const day   = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
    const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1
    const year  = d.getFullYear()
    return `${day}/ ${month}/ ${year}`
}

export const formatMyDateTwo = (dateStr) => { 
    const d = new Date(correctTimeZone(dateStr))
    const options = {
        year:  'numeric',
        month: 'short',
        day:   'numeric'       
    }    
    return d.toLocaleDateString('en-GB', options)     
}

export const formatMyDateTime = (dateStr) => {

    const d = new Date(dateStr)

    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    }
    
    const formattedDate = d.toLocaleDateString('en-GB', options) // 8 June 2023 at 23:53
    
    return formattedDate
}

export const formatDateDay = (dateStr) => {  
    const d = new Date(correctTimeZone(dateStr))  
    const options = {        
        day: 'numeric'       
    }
    let day = d.toLocaleDateString('en-GB', options)
    day = day < 10 ? `0${day}` : day
    return day
}

export const formatDateMonth = (dateStr, long) => {
    const d = new Date(correctTimeZone(dateStr)) 
    const options = {        
        month: long ? 'long' : 'short'     
    }
    return d.toLocaleDateString('en-GB', options)   
}

export const formatDateYear = (dateStr) => {
    const d = new Date(correctTimeZone(dateStr)) 
    const options = {        
        year: 'numeric'   
    }
    return d.toLocaleDateString('en-GB', options) 
}

export const formatDayMonthDate = (dateStr) => {
    const day = new Date(dateStr).getDate()
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ]
    const month = monthNames[new Date(dateStr).getMonth()]
    return `${day}${getDaySuffix(day)} ${month}`
}

export const isValidDate = (dateStr) => {   
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateStr)) {
        const parsedDate = parse(dateStr, 'dd/MM/yyyy', new Date())  
        if (parsedDate.toString() !== 'Invalid Date') return true
    }
    return false
}
  
export const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
}

// Calculation functions

export const calculateAmountToPay = (booking, discount, deposit) => {
    let total = 0
    for (let i = 0; i < booking?.guestArray.length; i++) {
        let price = booking.guestArray[i].cabinPrice        
        total += price - (price * (discount / 100))
        if (booking.agentDiscount > 0) {
            total = total * (1 - booking.agentDiscount / 100)
        }
        if (booking.agentDiscount === 0) {
            total += booking.guestArray[i].fees
            total += booking.guestArray[i].coursePrice
            total += booking.guestArray[i].crewPak ? Number(process.env.REACT_APP_PADI_CREWPAK) : 0
            total += booking.guestArray[i].equipmentPrice
        }        
    }  
    if (deposit !== 0) {
        total = deposit * (total / 100)
    } 
    // return Math.round(total) 
    return total
}  

export const calculateTotal = (booking, deposit) => {  
    let total = 0
    if (booking?.paymentInfo?.status === 'COMPLETED' && booking.total) {
        return booking.total
    }      
    for (let i = 0; i < booking?.guestArray?.length; i++) {         
        total += booking.guestArray[i].cabinPrice - (booking.guestArray[i].cabinPrice * (booking.discount / 100) )
        if (booking.agentDiscount) {
            total = total * (1 - booking.agentDiscount / 100)
        }
        if (booking.agentDiscount === 0) {
            total += booking.guestArray[i].fees
            total += booking.guestArray[i].coursePrice
            total += booking.guestArray[i].crewPak ? Number(process.env.REACT_APP_PADI_CREWPAK) : 0
            total += booking.guestArray[i].equipmentPrice
        }
    }  
    if (deposit) {
        total = total / (100 / deposit)
    } 
    // return Math.round(total)  
    return total      
} 

export const calculateDueDate = (booking) => {

    const d = new Date(booking?.departure)
    const thirtyDaysBefore = new Date(d)
    thirtyDaysBefore.setDate(d.getDate() - 30)

    const _d = new Date(booking?.createdAt ? booking?.createdAt : new Date())
    const threeDaysLater = new Date(_d)
    threeDaysLater.setDate(_d.getDate() + 3)

    let dateDue = threeDaysLater
    
    if (booking?.paymentInfo?.status === 'PENDING' || !booking?.paymentInfo?.status) { 
        if (threeDaysLater > thirtyDaysBefore) {
            dateDue = new Date()            
        } 
    } else if (booking?.paymentInfo?.status === 'DEPOSIT PAID') {    
        dateDue = thirtyDaysBefore < new Date() ? new Date() : thirtyDaysBefore
    } else if (booking?.paymentInfo?.status === 'COMPLETED') {
        dateDue = null
    }   
     
    return dateDue
}

export const calcDepositDueDate = (departure) => {
    if (!departure) return
    const originalDate = new Date(departure)
    const modifiedDate = new Date(originalDate)
    modifiedDate.setUTCDate(originalDate.getUTCDate() - 30)
    return modifiedDate.toISOString()
}

export const calculateTotalSpaces = (cabins) => {
    let t = 0
    t += cabins.master.spaces
    t += cabins.deluxe.spaces
    t += cabins.standard.spaces
    t += cabins.budget.spaces        
    return t    
}

export const charterAvailable = (product) => {
    const boat = product.boatName === 'MV Pawara' ? 1 : 0
    if (calculateTotalSpaces(product.cabins) === 26 && boat === 1) {
        return true
    }
    if (calculateTotalSpaces(product.cabins) === 30 && boat === 0) {
        return true
    }
    return false
}

export const backgroundColor = (status) => {
    let color = 'var(--cta-required)'
    if (status === 'COMPLETED') {
        color = 'var(--cta-discount-two)'
    }
    if (status === 'DEPOSIT PAID') {
        color = 'var(--cta-information)'
    }
    return color
}